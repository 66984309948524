import React from 'react';
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { colors } from '../style/colors'
import logo from '../images/logo.svg';

const HeaderElem = styled.header`
  background: ${colors.dark};
  height: 64px;
  display: flex;
  align-items: center;
  width: 100%;
`

const LogoWrap = styled.div`
  max-width: 200px;
  display: flex;
  align-items: center;
`

const LogoImage = styled.img`
  width: 100%;
`

const Name = styled.div`
  color: white;
  font-weight: 500;
`

export const Header = ({ name, email }) => (
  <HeaderElem>
    <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <LogoWrap href="https://bigcompass.com">
        <LogoImage src={logo} alt="Big Compass Logo" />
      </LogoWrap>
      {(name || email) && <Name>Logged in as {name || email}</Name>}
    </Container>
  </HeaderElem>
)