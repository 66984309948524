import React from 'react'
import { Header } from './components/Header'
import { LeadForm } from './components/LeadForm'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { colors } from './style/colors'
import { SnackbarProvider } from 'notistack'
import { setAuthTokenFromUrl, getAuthTokenFromCookie, getLoginUrl } from "./services/auth"

// Save the jwt if it is set in the url
setAuthTokenFromUrl();

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: colors.primary,
    }
  },
});

export default function App() {
  const token = getAuthTokenFromCookie()
  if (!token && process.env.REACT_APP_ENVIRONMENT === 'prod') {
    window.location.href = getLoginUrl()
  }

  const defaultValues = {}
  console.log('authToken: ', token)
  let firstName, lastName, email
  const jsonSection = token && token.split('.')[1]
  if (jsonSection) {
    try {
      console.log('jsonSection: ', jsonSection)
      const authObj = JSON.parse(Buffer.from(jsonSection, 'base64').toString())
      console.log('authObj: ', authObj)
      firstName = authObj.given_name
      lastName = authObj.family_name
      email = authObj.email
      if (firstName && lastName) { 
        defaultValues.name = firstName + ' ' + lastName
      }
      if (email) {
        defaultValues.email = email
      }
      console.log('email: ', email)
      console.log('firstName: ', firstName)
      console.log('lastName: ', lastName)
    } catch (err) {
      console.warn('Error occurred parsing auth token: ', err)
    }
  }
  
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
          <Header {...defaultValues} />
          <LeadForm authToken={token} defaultValues={defaultValues} />
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  )
}