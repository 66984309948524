import qs from "qs";
import Cookies from "js-cookie";
const JWT = "bcleads_jwt";

export const getLoginUrl = () =>
  'https://bigcompass-leads.auth.us-east-1.amazoncognito.com/login?client_id=3j436g4on684entoaff8i8laf4&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri='
  + process.env.REACT_APP_BASE_URL

export const setAuthTokenFromUrl = () => {
  // Save pertinent url param data
  let urlParamStr = window.location.hash;
  if (urlParamStr) {
    urlParamStr = urlParamStr.slice(1, urlParamStr.length);
    console.log(urlParamStr);
    const params = qs.parse(urlParamStr);
    console.log(params);
    if (params.id_token) {
      Cookies.set(JWT, params.id_token);
      window.location.hash = '';
    }
  }
};

export const getAuthTokenFromCookie = () => Cookies.get(JWT);
export const validateAuthToken = () => {
  const jwt = getAuthTokenFromCookie();
  if (!jwt && process.env.REACT_APP_ENVIRONMENT === 'prod') {
    window.location.href = getLoginUrl();
  } else {
    return true;
  }
};

export const clearAuthTokenCookie = () => {
  Cookies.remove(JWT);
};
