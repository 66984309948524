import React, { useState } from 'react'
import { FormContainer, Form, LoadingIndicator } from './LeadForm.style'
import { Button, TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import useAxios from 'axios-hooks'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack';
import { getLoginUrl } from '../services/auth';

const filter = createFilterOptions()
export const LeadForm = ({ authToken, defaultValues }) => {

  const [{ data: availableOrgs = [], loading, error }] = useAxios({
    url: process.env.REACT_APP_API_URL + '/pipedrive/orgs',
    method: 'GET',
    headers: {
      'x-api-token': authToken
    }
  })

  if (error && error.response && error.response.status === 401) {
    window.location.href = getLoginUrl()
  }

  const [submitting, setSubmitting] = useState()
  const { enqueueSnackbar } = useSnackbar();

  // Setup react-hook-form
  console.log('defaultValues: ', defaultValues)
  const { handleSubmit, errors, control, reset } = useForm({
    defaultValues: {
      name: '',
      email: '',
      title: '',
      org: null,
      notes: '',
      ...defaultValues
    }
  });

  const onSubmit = async (values) => {
    if (submitting) {
      return
    }

    // Submit form data to pipedrive
    setSubmitting(true)

    try {
      const payload = { ...values }
      if (defaultValues.name) payload.name = defaultValues.name
      if (defaultValues.email) payload.email = defaultValues.email
      await axios.post(process.env.REACT_APP_API_URL + '/pipedrive/save-deal', payload, {
        headers: {
          'x-api-token': authToken
        }
      })

      enqueueSnackbar('Successfully submitted lead', { variant: 'success' })
      reset()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('An error occurred submitting lead', { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
    
  }

  if (loading) {
    return <LoadingIndicator />
  } else if (error && !(error.response && error.response.status === 401)) {
    return <h1 style={{textAlign: 'center'}}>An error occurred pulling in required data</h1>
  } else if (!error) {
    return (
      <FormContainer>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <h1>Big Compass Lead Form</h1>
          <p>Enter details about your lead below</p>
          
          {!defaultValues.name &&
            <Controller
              name="name"
              control={control}
              rules={{ required: "Required" }}
              render={props => (
                <TextField
                  label="Your name"
                  placeholder="Enter your name"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  fullWidth
                  required 
                  {...props}
                />
              )}
            />
          }
          

          {!defaultValues.email &&
            <Controller
              name="email"
              control={control}
              render={(props) => (
                <TextField
                  label="Your email address"
                  type="email"
                  placeholder="Enter your email address"
                  variant="outlined"
                  fullWidth
                  required 
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  {...props}
                />
              )}
              rules={{
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
          }
          

          <Controller
            name="title"
            control={control}
            rules={{ required: "Required" }}
            render={props => (
              <TextField
                label="Opportunity Title"
                placeholder="Enter an appropriate title for this opportunity"
                variant="outlined"
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
                fullWidth
                required 
                {...props}
              />
            )}
          />

          <Controller
            name="org"
            control={control}
            render={({ onChange, ...props }) => (
              <Autocomplete
                options={availableOrgs}
                getOptionLabel={org => org.name}
                fullWidth
                onChange={(e, data) => {
                  if (typeof data === 'string') {
                    onChange({ name: data, isNew: true })
                  } else if (data && data.inputValue) {
                    onChange({ name: data.inputValue, isNew: true })
                  } else {
                    onChange(data)
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '' && !options.find(opt => opt.name === params.inputValue)) {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                clearOnBlur
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Associated Org"
                    placeholder="Search and select an organization"
                    variant="outlined" 
                  />
                )}
                {...props}
              />
            )}
          />

          <Controller
            name="notes"
            control={control}
            render={props => (
              <TextField
                multiline
                label="Notes"
                placeholder="Enter some notes about this lead"
                variant="outlined"
                fullWidth
                {...props}
              />
            )}
          />
          
          <Button type="submit" variant="contained" size="large" color="primary" fullWidth disabled={submitting}>
            Submit Lead
          </Button>
        </Form>
      </FormContainer>
    )
  } else {
    return null
  }
}