import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

export const FormContainer = styled.section`
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 32px 24px;
  background: rgb(242, 242, 242);
`

export const Form = styled.form`
  padding: 32px;
  box-shadow: rgba(0, 0, 0, 0.09) 3px 5px 5px;
  width: 100%;
  background: white;
  max-width: 600px;
  margin: 0 auto;
  h1 {
    margin: 0;
    text-align: center;
    + p {
      color: #888;
      text-align: center;
      margin: 12px 0;
    }
  }
  > * + * {
    margin-top: 24px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #f05438d6;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f05438d6;
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
  button[type=submit] {
    margin-top: 32px;
  }
`

export const LoadingIndicator = styled(CircularProgress)`
  width: 80px!important;
  height: 80px!important;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 200px;
`